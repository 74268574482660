.ql-editor {
    min-height: 300px;
}

.terminal-container {
    height: 600px;
    border: 1px solid #000000;
    background-color: #000000;
    color: #fefefe;
    overflow: scroll;
    display: flex;
 
}